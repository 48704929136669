export default {
  home: {
    minute: 'minut',
    price_info: '{mianfei} minutit tasuta, maksimaalselt päevas  {currencyName}{fengding}, deposiit {currencyName}{yajin},  tasuta renditagatis',
    feature_1: 'Spetsiaalne disain juhtmevabaks laadimiseks',
    feature_2: 'Riiklik universaalne laenamine ja tagasimaksmine',
    feature_3: 'Neli laadimisrežiimi, mis sobivad kõikidele mobiiltelefonidele',
    submit: 'Lae uuesti',
    borrow: 'Laenuta',
    inputcodetitle: 'Sisesta seadme number kapile',
    msg_rentcount: 'Sul on praegu {zujieNum} tellimusi, mis ei ole suletud',
    cancel: 'Tühista',
    confirm: 'Kinnita',
    msg_fail_title: 'Error',
    msg_fail_content: 'Laenutus ebaõnnestus, proovi uuesti',
    shoptime: 'Äriaeg',
    supportrental: 'Toeta laadimist',
    supportreturn: 'Toeta tagastamist',
    useragreen: 'Vali nõustumiseks "Debiteeritud maksevolituse vorm", "Kasutusleping", "Täiendusleping',
    privacyagreen: 'Privaatsuslepinguga nõustumiseks vajuta nuppu',
    offline: 'Seade {devicecode} on offline, võtke ühendust meeskonnaga',
    permissionfail: 'Luba ei antud.',
    unbound: 'QR-kood {devicecode} ei ole seadmega seotud',
    nobattery: 'Seadmes pole akut saadaval {devicecode}',
    rentwithoutapp: 'Rent without app',
    downloadapp: 'Laadige rakendus alla'
  },
  loginborrow: {
    powerbanktitle: 'LAENUTA AKUPANK',
    sign_in_to_rent: 'Logi sisse laenutamiseks',
    msg_fail_title: 'Error',
    msg_fail_user: 'Viga kasutajateabe hankimisel. Kas soovite uuesti proovida?',
    msg_rentcount: 'Teil on praegu {zujieNum} tellimusi, mis ei ole lõppenud',
    msg_fail_support: 'Süsteemi ei toetata',
    msg_fail_confirm: 'Kinnita',
    msg_fail_retry: 'Proovi uuesti',
    msg_fail_cancel: 'Tühista',
    msg_fail_order: 'Tellimuse sooritamine ebaõnnestus',
    msg_fail_paymod: 'Makseviisi hankimine ebaõnnestus',
    msg_fail_login: 'Sisselogimine ebaõnnestus',
    Apple: 'Sign in with Apple',
    otheropt: 'Teised valikud',
    selectmethod: 'Vali makseviis',
    paymentdetail: 'Makseviisi üksikasjad',
    couponcode: 'Kupongi kood',
    rent: 'Kinnita makse',
    pricing: 'Vaata hinnakirja',
    phone: 'Telefon',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Logi sisse Facebookiga',
    logingg: 'Logi sisse Googlega',
    captcha_code: 'Kood',
    phonenumber: 'Sisesta oma telefoninumber',
    msg_fail_code: 'Sisesta kinnituskood',
    msg_fail_phone: 'Siesta oma telefoni number',
    logout: 'Välju kasutajakontost',
    savenow: 'Salvesta',
    delete: 'Kustuta',
    addcard: 'Sisesta kaardi andmed',
    msg_fail_content: 'Laenutamine ebaõnnestus, proovige uuesti',
    title: 'Kinnitage volitus',
    policy: 'Eeskirjad.',
    term: ' tingimustega',
    and: 'ja',
    privacy: 'privaatsuspoliitika.',
    tips: 'Jätkates nõustute meie',
    cancel: 'TÜHISTA',
    per_hour: 'üks tund',
    per_day: 'max. päev',
    agree: 'NÕUSTU'
  },
  preauth: {
    preauthortitle: 'Eelautoriseerimise kohta',
    tip1: 'Laenutamise alustamisel tehakse teie makseviisile eelautoriseerimine. Selle eesmärk on tagada, et teie laenutamise eest tasumiseks oleks piisavalt raha.',
    tip2: 'Teie pank võib teile teatise saata, kuid see ei ole tasuline: tasutakse ainult teie laenutuse hinda.',
    tip3: 'Olenevalt teie pangast võib summa korrigeerimine teie väljavõtetel võtta mõnest tunnist kuni mõne päevani. See on tavapärane.'
  },
  rentcost: {
    rentcosttitle: 'Kui palju maksab laenutamine?',
    tip1: 'Kui te akupanka 96 tunni jooksul ei tagasta, võetakse teilt mittetagastamise tasu {currency} {yajin} .',
    tip2: 'Kestus ümardatakse lähima tunnini(60 minutit).',
    tip3: 'Ühe päeva rent on 24 tundi alates akupanga laenutamisest.',
    tip3_1: 'Eelautoriseerimise tasu {currency}{currencyName} {cyajin} kantakse teie makseviisile. Selle eesmärk on tagada, et teie laenutamise eest tasumiseks oleks piisavalt raha.',
    tip3_2: 'Teie pank võib teile teatise saata, kuid see ei ole tasu; tasutakse ainult teie rendi hind.',
    tip3_3: 'Olenevalt teie pangast võib teie väljavõtetel summa korrigeerimiseks kuluda mõni tund või kuni paar päeva.',
    tip4: 'Tutvuge laenutusjaamade hinnakirjadega. Mõnedes laenutusjaamades pakutakse tasuta krediiti või allahindlusi laenutamiseks. Kena akude laadimist!',
    Days: 'Päevi',
    Hours: 'Tunde',
    Minutes: 'Minuteid'
  },
  logincode: {
    login: 'Sisselogimine',
    retry: 'Saada uuesti',
    title: 'Kinnituskood',
    subtitle: 'Sisestage kinnituskood, mille saatsime teie telefoninumbrile'
  },
  agreenconfirm: {
    title: 'Kinnitage volitus',
    policy: 'Eeskirjad.',
    term: "Tehnoloogia nõuded ja tinfimused",
    and: 'ja',
    privacy: 'Privaatsuspoliitika.',
    tips: 'Nõustun ',
    cancel: 'TÜHISTA',
    agree: 'NÕUSTUN'
  },
  login: {
    login_fail: 'Sisselogimine ebaõnnestus',
    phonenumber: 'Telefoni number',
    verificationcode: 'Kinnituskood',
    Rentpowerbank: 'Laenuta akupank',
    Rentpowerbanktip: 'Alustamiseks sisestage oma mobiiltelefoni number',
    phone_number: 'Sisestage {0}-kohaline mobiilinumber ',
    captcha_code: 'Kood',
    send_code: 'Saada kood',
    login: 'Sisselogmine',
    agree: 'Klõpsates nuppu Logi sisse, nõustute',
    readme: `Kasutusleping`,
    msg_fail_login: 'Sisselogimine ebaõnnestus',
    msg_success_login: 'Sisselogimine õnnestus',
    msg_success_login_code: 'Õnnestus, kasutamiseks sisselogimiseks laadige rakendus alla',
    msg_fail_user: 'Viga kasutajateabe hankimisel. Kas soovite uuesti proovida?',
    no_sms: 'Tekstsõnumit ei saanud',
    retry: 'Saada uuesti',
    receive: 'Taotle',
    invite_pwd: 'Kutse kood ',
    vip_agreen_tip: 'Jätkates nõustute meie',
    vip_agreen_service: 'Kasutustingimustega',
    vip_agreen_and: 'ja',
    vip_agreen_policy: 'Privaatsuspoliitikaga'
  },
  confirm: {
    title: 'Maksekinnitus',
    pay_label: 'Summa',
    addcard: 'Lisa kaart rahakotti',
    price_tips: 'Klõpsake makse kinnitamiseks, mis näitab, et olete lugenud ja nõustute ',
    price_btn: '《Value-added Agreement》',
    pay_type: 'Valige eelistatud makseviis',
    submit: 'Kinnita laenutamine',
    charge: 'Kinnita uuesti laadimine',
    msg_fail_title: 'Hoiatus',
    msg_fail_order: 'Laenutamine ebaõnnestus',
    msg_fail_confirm: 'Kinnita',
    msg_fail_retry: 'Uuesti',
    msg_fail_cancel: 'Tühista'
  },
  join: {
    title: 'Partnership',
    call: 'Call',
    tips: 'please provide your information, we will contact you shortly!',
    type1: 'Installation',
    type2: 'Advertising',
    type3: 'Other',
    shop_name: 'Merchant ’s name',
    contact_person: 'Contact Person',
    phone: 'Contact Number',
    submit: 'Submission',
    noempty: 'Cannot be empty'
  },
  mine: {
    title: 'User Profile',
    wallet: 'My Wallet',
    balance: 'My Balance',
    mywalletcard: 'My Card',
    addcard: 'Add Wallet Card',
    deposit: 'Deposiit',
    wallet_in: 'Charge',
    wallet_out: 'Withdraw',
    wallet_log: 'Wallet Log',
    order: 'Order',
    login_tips: 'Please Login',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error getting user information. Do you want to try again?',
    help: 'Help',
    setting: 'Setting',
    logout: 'Logout',
    unpaid: 'Unpaid',
    using: 'In Use'
  },
  msg: {
    appoffline: "La aplicación aún no se ha lanzado, por favor espere",
    nopaymode: 'Makseviisi omandamine ebaõnnestus',
    unlogin: 'Sa pole veel sisse loginud, palun logige sisse',
    msg_fail_title: 'Hoiatus',
    deletecard: 'Kas oled kindel, et soovid kaarti tühistada?',
    loading: 'Laadib...',
    title: 'Vihjed',
    success: 'Edukas operatsioon',
    error: 'Tundmatu viga, proovige hiljem uuesti.',
    userinfo_fail: 'Kasutaja andmed on kehtetud, logige uuesti sisse',
    roleerror: 'Ebapiisavad õigused, konsulteerige administraatoriga',
    neterror: 'Võrguühendus ebaõnnestus. Palun proovi hiljem uuesti',
    confirm: 'Kinnita',
    retry: 'Uuesti',
    cancel: 'Tühista',
    msg_logout: 'Kas olete kindel, et soovid kontot välja logida ?',
    vieworder: 'Vaata tellimust',
    addorselectcard: 'Jätkamiseks lisage või valige kaart',
    success_payment: 'Makse kinnitatud',
    fail_payment: 'Makse ebaõnnestus',
    keyword: 'märksõna',
    unpaid_order: 'Sul on tasumata tellimusi',
    go_pay: 'Mine maksma',
    no_more: 'mitte rohkem',
    noshop: 'Kaupmehi pole',
    msg_fail_code: 'Palun sisesta kinnituskood',
    msg_fail_phone: 'Palun sisesta oma telefoni number ',
    exceedorder: 'Teil on juba aktiivne tellimus, palun tagastage see enne jätkamist'
  },
  result: {
    title: 'Rentimine kinnitatud',
    tips: 'Tellimuste ja muude jaama asukohtade vaatamiseks laadige rakendus alla.',
    download: 'LAE ALLA',
    vieworder: 'Vaata tellimust',
    msg_result_loading: 'Makse on kinnitamisel...',
    msg_tips_loading: 'Masin vabastab kohe akupanga, oodake...',
    haveorder: 'Tellies on loodud'
  },
  result_msg: {
    loading: 'Lae...',
    refresh: 'Lae uuesti'
  },
  order: {
    title: 'Tellimus',
    in_use: 'Kasutuses',
    ended: 'Lõppenud',
    msg_error: 'Päring ebaõnnestus',
    no_data: 'Andmed puuduvad'
  },
  order_item: {
    order_number: 'Ei.',
    venue_rented: 'Asukoht kust renditi',
    time_rented: 'Rentimise aeg',
    venue_returned: 'Asukoht kuhu tagastati',
    time_returned: 'Tagastamise aeg',
    charge_standard: 'Laadimisstandard',
    rent_occurred: 'Rent tekkis',
    returned: 'Tagastatud',
    unreturned: 'Tagastamata',
    type_0: 'Lõpetamata',
    type_1: 'Rentimisel',
    type_2: 'Tühistatud',
    type_3: 'Tagastatud',
    type_4: 'Tekkis viga',
    type_5: 'Üle aja',
    min: 'Min',
    unpaid: 'Maksmata',
    payment: 'Makse'
  },
  setting: {
    title: 'Seadistamine',
    lang: 'Keel'
  }
}
